.behance_wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 20px;
  overflow-x: scroll;
}

*::-webkit-scrollbar {
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--dark-blue);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background: var(--grey);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 768px) {
  iframe {
    height: 280px;
    width: 350px;
  }
}
