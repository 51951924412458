.about_Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  border-radius: 10px;
  background-color: var(--neon-green);
  color: var(--bg-black);
  padding: 30px 60px;
  text-align: left;
  gap: 20px;
}

.about-Heading {
  font-size: 4rem;
  font-family: "Humane";
}

.about_Desc {
  font-size: 1.3rem;
  font-family: "Nunito-Regular";
}
.more_About {
  font-family: "Nunito-Bold";
  text-decoration: none;
  color: var(--neon-green);
  padding: 4px 10px;
  border-radius: 5px;
  background-color: var(--dark-blue);
  display: flex;
  margin-left: auto;
}

@media (max-width: 1367px) {
  .about_Container {
    align-items: flex-start;
    gap: 10px;
    padding: 40px 60px;
  }
}
@media screen and (max-width: 768px) {
  .about_Container {
    margin: 0 1rem;
    padding: 40px 20px;
    align-items: flex-start;
    text-align: left;
    margin: 0;
  }
  .about-Heading {
    font-size: 3rem;
    margin-bottom: 0;
  }
  .about_Desc {
    font-size: 1rem;
  }
  .more_About {
    font-size: 0.8rem;
  }
}
