@import "./common/styles/variable.css";
@import "./common/fonts/fonts.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.3s ease;
}
body {
  background-color: var(--bg-black);
}
