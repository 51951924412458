body {
  color: var(--white);
  font-family: "Nunito-Regular";
}
.go-back {
  display: none;
  position: fixed;
  top: 35px;
  left: 20px;
  text-decoration: none;
}
.projectspage-section {
  margin: 0 100px;
}

.projectspage-heading {
  font-family: "Humane";
  font-size: 2rem;
  text-align: center;
  padding: 20px 40px;
  background-color: var(--black-blue);
  color: var(--white);
  opacity: 0.9;
  border-radius: 10px;
  margin-bottom: 20px;
}
.projectspage {
  /* padding: 10px; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 2rem;
}

.projectpage-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: 20px;
  overflow: hidden;
  font-family: "DM Sans";
  background-color: var(--black-blue);
  border: 2px solid transparent;
  text-decoration: none;
  color: var(--white);
  overflow: hidden;
}

.projectpage-image {
  height: fit-content;
  object-fit: cover;
}

.projectpage-desc {
  margin: 20px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
}

.projectpage-desc h2 {
  font-family: "Amulya-Bold";
  font-size: 24px;
  white-space: nowrap;
}

.proj__descrption {
  font-family: "Nunito-Regular";
  font-size: 18px;
  margin: 10px 0px 10px 0;
  color: var(--grey);
}
.project_type {
  color: var(--neon-green);
  margin-top: 5px;
}
.projectpage-tech {
  padding: 5px 5px 5px 0;
  height: 3.2rem;
}

.projectpage-container:hover {
  border: 2px solid #0000;
  background: linear-gradient(#131219, #131219) padding-box,
    linear-gradient(var(--angle), #070707, var(--neon-green)) border-box;
  animation: 8s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

.prj-card-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.workInProgress {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  background-color: var(--bg-grey);
  padding: 6px 10px;
  border-radius: 20px;
  color: var(--grey);
  font-family: "Nunito-Bold";
  font-size: 1rem;
  font-weight: 500;
}
.demo-btn {
  padding: 10px 20px;
  border-radius: 10px;
  font-family: "DM Sans";
  font-weight: 500;
  text-transform: uppercase;
  background-color: var(--neon-green);
}

@media (max-width: 1367px) {
  .projectspage {
    grid-template-columns: repeat(3, 1fr);
  }
  .projectspage-section {
    margin: 0 50px;
  }
  .projectpage-container {
    border: 2px solid #0000;
    background: linear-gradient(#131219, #131219) padding-box,
      linear-gradient(var(--angle), #070707, var(--neon-green)) border-box;
    animation: 8s rotate linear infinite;
  }
  .projectpage-desc {
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
    padding: 0;
    margin: 20px;
    gap: 10px;
  }
  .projectpage-desc p {
    font-size: 0.8rem;
    margin: 0;
    height: fit-content;
  }
  .projectpage-desc h2 {
    font-size: 1.3rem;
  }

  .projectpage-tech {
    height: 3.3rem;
  }
}
.techstack-edit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  gap: 7px;
}
.expo {
  height: 40px;
  border-radius: 6px;
}
@media (max-width: 1025px) {
  .projectspage {
    grid-template-columns: repeat(1, 1fr);
  }
  .projectspage-section {
    margin: 0 50px;
  }
  .projectpage-container {
    border: 2px solid #0000;
    background: linear-gradient(#131219, #131219) padding-box,
      linear-gradient(var(--angle), #070707, var(--neon-green)) border-box;
    animation: 8s rotate linear infinite;
  }
  .projectpage-desc {
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .go-back {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px 10px;
    background-color: var(--black-blue);
    border-radius: 5px;
    cursor: pointer;
    font-size: 10px;
    font-weight: bold;
    color: var(--white);
    transition: 0.3s;
  }
  .projectspage {
    grid-template-columns: repeat(1, 1fr);
    padding: 30px 0;
  }
  .projectspage-section {
    font-size: 2rem;
    margin: 20px 0;
  }
  .projectspage-heading {
    text-align: center;
    font-size: 2rem;
    border-radius: 10px;
    margin: 0 20px 0px 20px;
  }
  .projectspage {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
  }
  .projectpage-container {
    height: fit-content;
    border: 2px solid #0000;
    background: linear-gradient(#131219, #131219) padding-box,
      linear-gradient(var(--angle), #070707, var(--neon-green)) border-box;
    animation: 8s rotate linear infinite;
  }
  .projectpage-desc {
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
    padding: 0;
    margin: 20px;
    gap: 5px;
  }
  .projectpage-desc p {
    font-size: 0.8rem;
    margin: 0;
    height: fit-content;
  }
  .projectpage-desc h2 {
    font-size: 1.3rem;
  }

  .projectpage-tech {
    height: 2.9rem;
  }
  .workInProgress {
    font-size: 0.7rem;
  }
  .workInProgress p {
    font-size: 0.7rem;
  }
  .project-card-heading {
    width: 50%;
  }
  .expo {
    height: 2.2rem;
    border-radius: 6px;
  }
  .warp-text {
    display: none;
  }
}
