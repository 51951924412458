@font-face {
  font-family: 'Nunito-ExtraLight';
  src: url('../fonts/Nunito-ExtraLight.woff2') format('woff2'),
       url('../fonts/Nunito-ExtraLight.woff') format('woff'),
       url('../fonts/Nunito-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-ExtraLightItalic';
  src: url('../fonts/Nunito-ExtraLightItalic.woff2') format('woff2'),
       url('../fonts/Nunito-ExtraLightItalic.woff') format('woff'),
       url('../fonts/Nunito-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito-Light';
  src: url('../fonts/Nunito-Light.woff2') format('woff2'),
       url('../fonts/Nunito-Light.woff') format('woff'),
       url('../fonts/Nunito-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-LightItalic';
  src: url('../fonts/Nunito-LightItalic.woff2') format('woff2'),
       url('../fonts/Nunito-LightItalic.woff') format('woff'),
       url('../fonts/Nunito-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito-Regular';
  src: url('../fonts/Nunito-Regular.woff2') format('woff2'),
       url('../fonts/Nunito-Regular.woff') format('woff'),
       url('../fonts/Nunito-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-Italic';
  src: url('../fonts/Nunito-Italic.woff2') format('woff2'),
       url('../fonts/Nunito-Italic.woff') format('woff'),
       url('../fonts/Nunito-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito-Medium';
  src: url('../fonts/Nunito-Medium.woff2') format('woff2'),
       url('../fonts/Nunito-Medium.woff') format('woff'),
       url('../fonts/Nunito-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-MediumItalic';
  src: url('../fonts/Nunito-MediumItalic.woff2') format('woff2'),
       url('../fonts/Nunito-MediumItalic.woff') format('woff'),
       url('../fonts/Nunito-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito-SemiBold';
  src: url('../fonts/Nunito-SemiBold.woff2') format('woff2'),
       url('../fonts/Nunito-SemiBold.woff') format('woff'),
       url('../fonts/Nunito-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-SemiBoldItalic';
  src: url('../fonts/Nunito-SemiBoldItalic.woff2') format('woff2'),
       url('../fonts/Nunito-SemiBoldItalic.woff') format('woff'),
       url('../fonts/Nunito-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito-Bold';
  src: url('../fonts/Nunito-Bold.woff2') format('woff2'),
       url('../fonts/Nunito-Bold.woff') format('woff'),
       url('../fonts/Nunito-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-BoldItalic';
  src: url('../fonts/Nunito-BoldItalic.woff2') format('woff2'),
       url('../fonts/Nunito-BoldItalic.woff') format('woff'),
       url('../fonts/Nunito-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito-ExtraBold';
  src: url('../fonts/Nunito-ExtraBold.woff2') format('woff2'),
       url('../fonts/Nunito-ExtraBold.woff') format('woff'),
       url('../fonts/Nunito-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-ExtraBoldItalic';
  src: url('../fonts/Nunito-ExtraBoldItalic.woff2') format('woff2'),
       url('../fonts/Nunito-ExtraBoldItalic.woff') format('woff'),
       url('../fonts/Nunito-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito-Black';
  src: url('../fonts/Nunito-Black.woff2') format('woff2'),
       url('../fonts/Nunito-Black.woff') format('woff'),
       url('../fonts/Nunito-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-BlackItalic';
  src: url('../fonts/Nunito-BlackItalic.woff2') format('woff2'),
       url('../fonts/Nunito-BlackItalic.woff') format('woff'),
       url('../fonts/Nunito-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito-Variable';
  src: url('../fonts/Nunito-Variable.woff2') format('woff2'),
       url('../fonts/Nunito-Variable.woff') format('woff'),
       url('../fonts/Nunito-Variable.ttf') format('truetype');
  font-weight: 200 1000;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-VariableItalic';
  src: url('../fonts/Nunito-VariableItalic.woff2') format('woff2'),
       url('../fonts/Nunito-VariableItalic.woff') format('woff'),
       url('../fonts/Nunito-VariableItalic.ttf') format('truetype');
  font-weight: 200 1000;
  font-display: swap;
  font-style: italic;
}

