body {
  color: var(--white);
}
a {
  text-decoration: none;
}
.about-go-back {
  display: none;
  font-family: "Nunito-Regular";
  text-decoration: none;
}
.about_Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin: 0 100px;
}
.about_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--black-blue);
  padding: 40px 40px;
  border-radius: 10px;
  gap: 20px;
}
.about_L {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.about_Image {
  height: 28rem;
  border-radius: 10px;
}

.special {
  color: var(--neon-green);
  font-weight: 500;
}
.about_Buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.resume_Download {
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: transparent;
  color: var(--neon-green);
  font-family: "Nunito-Medium";
  border: 1px solid var(--neon-green);
  cursor: pointer;
  transition: 0.1s;
}
.resume_Download:hover {
  background-color: var(--neon-green);
  color: var(--dark-blue);
  border: 1px solid var(--neon-green);
}

.about_Heading {
  font-family: "Humane";
  font-size: 4rem;
  text-align: center;
  padding: 20px 40px;
  width: 100%;
  font-weight: 700;
  background-color: var(--black-blue);
  color: var(--white);
  opacity: 0.9;
  border-radius: 10px;
}
.about_R {
  font-family: "Nunito-Regular";
  font-size: 1.1rem;
  text-align: left;
  justify-content: center;
  padding: 10px;
  color: var(--white);
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--black-blue);
}

.carousel_sociallinks_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
}

.skills_Container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  text-align: left;
  padding: 30px;
  background-color: var(--black-blue);
  border-radius: 10px;
}
.carousel {
  display: flex;
  mask-image: linear-gradient(
    90deg,
    transparent,
    var(--bg-black) 20%,
    var(--bg-black) 80%,
    transparent
  );
}
.carousel-content {
  white-space: nowrap;
  width: 200%;
  height: fit-content;
  animation: slide 15s infinite linear;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.carousel-content img {
  height: 50px;
  margin: 0 10px;
}
.carousel:hover .carousel-content {
  animation-play-state: paused;
}

.SocialLinks_Container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: var(--black-blue);
  border-radius: 10px;
}
.Socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  font-size: 2.5rem;
}

.Socials a {
  color: var(--neon-green);
}
@media (max-width: 1025px) {
  .about_Wrapper {
    margin: 0 50px;
  }
  .about_container {
    padding: 20px 20px;
    flex-direction: column;
  }
  .about_Heading {
    font-size: 3rem;
  }
  .about_R {
    font-size: 1rem;
    text-align: center;
  }
  .about_Image {
    height: 350px;
  }
  .about_Buttons {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .skills_Container {
    width: 100%;
    font-size: 0.8rem;
    gap: 10px;
  }
  .carousel-content img {
    height: 40px;
    margin: 0 5px;
  }
  .carousel-content {
    white-space: nowrap;
    width: 300%;
  }
  .about_Wrapper {
    margin: 20px 20px;
    align-items: flex-start;
    gap: 10px;
  }
  .about_container {
    flex-direction: column;
    padding: 20px 20px;
  }
  .about_Image {
    height: 280px;
    border-radius: 5px;
  }
  .about_Heading {
    font-size: 3rem;
  }
  .about_R {
    font-size: 1rem;
    padding: 0;
    text-align: left;
  }
  .resume_Download {
    padding: 10px 20px;
    width: 100%;
  }
  .about-go-back {
    position: fixed;
    top: 40px;
    z-index: 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-size: 0.5rem;
    text-align: left;
    background-color: var(--black-blue);
    border: 1px solid var(--neon-green);
    padding: 10px 20px;
    border-radius: 5px;
  }
  .carousel_sociallinks_container {
    flex-direction: column;
    gap: 10px;
  }
  .Socials {
    font-size: 2rem;
  }
}
