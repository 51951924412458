.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.infinitehorizonscroll {
  opacity: 0.3;
  overflow: hidden;
  /* margin: 0 100px; */
}

.infinitehorizon1 {
  position: relative;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  line-height: fit-content;
  color: var(--bg-grey);
  -webkit-text-stroke-color: var(--neon-green);
  -webkit-text-stroke-width: 1.6px;
  font-family: "Organetto";
  font-size: 200px;
  white-space: nowrap;
  width: 1500px;
  mask-image: linear-gradient(
    90deg,
    transparent,
    var(--bg-black) 20%,
    var(--bg-black) 80%,
    transparent
  );
}

.infinitehorizon1 span {
  animation: scroll1 50s linear infinite;
  animation-delay: calc(50s * -1);
}
@keyframes scroll1 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.infinitehorizon1 span:nth-child(2) {
  animation: scroll2 50s linear infinite;
  animation-delay: calc(50s / -2);
}
@keyframes scroll2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

.infinitehorizon2 {
  position: relative;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  line-height: fit-content;
  color: var(--bg-grey);
  -webkit-text-stroke-color: var(--neon-green);
  -webkit-text-stroke-width: 1.6px;
  font-family: "Organetto";
  font-size: 200px;
  white-space: nowrap;
  width: 1500px;
  mask-image: linear-gradient(
    90deg,
    transparent,
    var(--bg-black) 20%,
    var(--bg-black) 80%,
    transparent
  );
  top: 30px;
}

.infinitehorizon2 span {
  animation: scroll3 50s linear infinite;
  animation-delay: calc(50s * -1);
}
.infinitehorizon2 span:nth-child(2) {
  animation: scroll4 50s linear infinite;
  animation-delay: calc(50s / -2);
}
@keyframes scroll3 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes scroll4 {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}

@media (max-width: 1366px) {
  .container {
    height: 99vh;
  }
  .infinitehorizonscroll {
    width: 99vw;
  }
  .infinitehorizon1,
  .infinitehorizon2 {
    font-size: 12rem;
    width: 90vw;
  }
}

@media (max-width: 768px) {
  .container {
    height: 70vh;
  }
  .infinitehorizonscroll {
    width: 100vw;
  }
  .infinitehorizon1 {
    margin-bottom: 70px;
  }
  .infinitehorizon1,
  .infinitehorizon2 {
    font-size: 7rem;
    width: auto;
  }
}
