@import "./amulya_font/css/amulya.css";
@import "./Nunito_font/nunito_css/nunito.css";
@font-face {
  font-family: Organetto;
  font-weight: 700;
  src: local("Organetto"), url("./Organetto-UltraBoldCnd.ttf") format(TrueType);
}
@font-face {
  font-family: Organetto;
  font-weight: 500;
  src: local("Organetto"), url("./Organetto-RegularCnd.ttf") format(TrueType);
}
@font-face {
  font-family: Organetto-ext;
  font-weight: 500;
  src: local("Organetto"), url("./Organetto-BoldSemiExt.ttf") format(TrueType);
}
@font-face {
  font-family: Humane;
  font-weight: 500;
  src: local("Humane"), url("./Humane-Bold.ttf") format("truetype");
}
@font-face {
  font-family: Humane;
  font-weight: 400;
  src: local("Humane"), url("./Humane-Medium.ttf") format("truetype");
}
